import React from "react"
import data from "../../data/members/memberData.json"
import Layout from "../components/layout"
import * as styles from "./members.module.css"
import Meta from "../components/meta.js"

export default function hoe() {
  return (
    <Layout>
      <Meta />
      <h1>
        <mark
          style={{ fontWeight: `700`, fontSize: `3rem`, marginTop: `2rem` }}
        >
          Members
        </mark>
      </h1>
      <div
        className={styles.container}
        style={{
          display: `flex`,
          flexWrap: "wrap",
        }}
      >

        {data.map(member => (
          <div
            className={styles.card}
            style={{
              padding: `0`,
              width: `300px`,
            }}
          >
            <img
              className={styles.image}
              src={member.avatar}
              alt={member.name}
              width={`300px`}
              height={`300px`}
              style={{
                  // zoom instead of scale
                objectFit: `cover`,
              }}
            />
            <div
              className={styles.textContainer}
              style={{ margin: `20px`, width: `290px` }}
            >
              <h1 className={styles.name}>{member.name}</h1>
              <h2 className={styles.post}>{member.post}</h2>
              <p className={styles.desc}>{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}
